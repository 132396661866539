import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { SectionHeader } from "../../components/SectionHeader";

export const SectionDescription = () => {
  const query = graphql`
    query shareResourceSection {
      mdx(fileAbsolutePath: { regex: "/content/sections/submit-resource/" }) {
        frontmatter {
          title
        }
        body
      }
    }
  `;

  const data = useStaticQuery(query);

  return (
    <SectionHeader title={data.mdx.frontmatter.title} body={data.mdx.body} />
  );
};
