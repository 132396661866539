import { Button, Flex, Link } from "@chakra-ui/react";
import React from "react";
import { Header } from "../../components/Header";
import { HeaderBar } from "../../components/HeaderBar";
import { Layout } from "../../components/Layout";
import { Main } from "../../components/Main";
import { SectionDescription } from "../../features/submit-resource/SectionDescription";

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Header>
        <HeaderBar />
      </Header>
      <Main pb="16" justifyContent="flex-start" alignItems="center">
        <Flex
          flexDirection="column"
          width="100%"
          maxWidth={["100%", null, null, "120ch"]}
        >
          <SectionDescription />
          <Link
            mt="8"
            width="100%"
            justifyContent="center"
            display="flex"
            href="https://forms.gle/gFcnT2Ftowtq1oEs8"
            _hover={{
              textDecoration: "none",
            }}
            isExternal
          >
            <Button colorScheme="red" width="200px">
              Submit Form
            </Button>
          </Link>
        </Flex>
      </Main>
    </Layout>
  );
};

export default IndexPage;
